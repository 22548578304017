import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Typography,
  Grid,
  CardContent,
  IconButton,
} from "@mui/material";
import { icons } from "./constant";
import PrimaryButton from "../../../components/Custom/PrimaryButton/PrimaryButton";
import { axiAuth } from "../../../utils/axiosInstance";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import DeleteIcon from "@mui/icons-material/Delete";

const IconFeatureSelector = ({
  selectedIcon,
  setSelectedIcon,
  title,
  setTitle,
  handleIconChange,
  handleTitleChange,
  editProductId,
}) => {
  const { token } = useAuth();
  console.log(token);

  const [prevFeatures, setPrevFeatures] = useState([]);
  const [prevFeatureLoading, setPrevFeatureLoading] = useState(true);
  const [prevError, setPrevError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, status } = await axiAuth.get(
          `api/admin/product-features?product_id=${editProductId}`
        );
        if (status === 200) {
          console.log(data);
          setPrevFeatures(data.features);
          setPrevFeatureLoading(false);
        }
      } catch (error) {
        setPrevError(error);
        setPrevFeatureLoading(false);
      }
    };

    fetchData();
  }, [editProductId]);

  const handleDeleteFeature = async (featureId) => {
    try {
      await axiAuth.delete(`api/admin/product-features/${featureId}`);
      setPrevFeatures((prevFeatures) =>
        prevFeatures.filter((feature) => feature.id !== featureId)
      );
    } catch (error) {
      console.log(error);
      // Handle error
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Do something with the title and selectedIcon, such as sending it to an API or updating state in a parent component
    console.log({ title, icon: selectedIcon });
    if (!title) {
      toast.warn("Title is needed for the feature");
      return;
    }

    try {
      const { status, data } = await axiAuth.post(
        `api/admin/product-features`,
        {
          product_id: editProductId,
          icon: selectedIcon,
          title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (status === 200) {
        toast.success("Feature Added");
        console.log(data);
        setPrevFeatures(data.features);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong while adding feature");
    }
  };

  if (prevFeatureLoading) {
    return <div>Loading...</div>;
  }

  if (prevError) {
    return <div>Error: {prevError.message}</div>;
  }

  return (
    <>
      <Typography variant={"h6"} sx={{ my: 3 }}>
        Features
      </Typography>
      <Box>
        <Grid container spacing={2}>
          {prevFeatures.map((feature) => (
            <Grid item xs={12} sm={6} md={4} key={feature.id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "primary.light",
                }}
              >
                <img height="40" src={feature.icon} alt="Icon" />
                <CardContent>
                  <Typography variant="h6">{feature.title}</Typography>
                </CardContent>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => handleDeleteFeature(feature.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography sx={{ my: 2 }} fontWeight={600}>
          Add Feature
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="icon-label">Select an icon</InputLabel>
          <Select
            labelId="icon-label"
            id="icon"
            value={selectedIcon}
            onChange={handleIconChange}
            label="Select an icon"
          >
            {/* {icons.map((icon, index) => (
                     <MenuItem key={index} value={icon}>
                        <img
                           src={icon}
                           alt='Icon'
                           style={{
                              width: '24px',
                              height: '24px',
                              marginRight: '8px',
                           }}
                        />
                     </MenuItem>
                  ))} */}
            <MenuItem value="https://solrufv1.s3.ap-south-1.amazonaws.com/sites-static-files-new/radio_button_checked.svg">
              <img
                src="https://solrufv1.s3.ap-south-1.amazonaws.com/sites-static-files-new/radio_button_checked.svg"
                alt="Icon"
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "8px",
                }}
              />
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="title"
          label="Title"
          defaultValue={title}
          onChange={handleTitleChange}
          sx={{ m: 1, minWidth: 200 }}
        />

        <PrimaryButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{ m: 1 }}
        >
          Add
        </PrimaryButton>
      </Box>
    </>
  );
};

export default IconFeatureSelector;
