import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import "./ProjectTimeline.css";
import { axiosInstance } from "../../utils/axiosInstance";
import { useParams } from "react-router";

const ProjectTimeline = () => {
  // Set up state for projectTimelines
  const { mobile } = useParams();
  const [items, setItems] = useState([]);
  const [steps, setSteps] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTimelines = async () => {
      try {
        const response = await axiosInstance.get(
          `https://api-dev-v1.solruf.com/api/timelines/${mobile}`
        );
        const customerResponse = await axiosInstance.get(
          `https://api-dev-v1.solruf.com/api/timeline-customer/${mobile}`
        );
        const customer = customerResponse?.data || null;
        const timelines = response?.data || [];

        // Convert the data into the format required for Chrono component
        const chronoItems = timelines.map((item) => ({
          // title: new Date(item?.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
          title: "",
          cardTitle: item?.title, // Using item.title as the card title
          cardDetailedText: item?.description, // This holds the description for each timeline event
          payment_link: item?.payment_link,
          documents: JSON.parse(item?.documents),
          created_at: item.created_at,
          updated_at: item.updated_at,
        }));

        const stepItems = timelines.map((item) => ({
          // title: new Date(item?.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
          title: "",
          cardTitle: item?.title, // Using item.title as the card title
          cardDetailedText: item?.description, // This holds the description for each timeline event
          payment_link: item?.payment_link,
          documents: JSON.parse(item?.documents),
          created_at: item.created_at,
          updated_at: item.updated_at,
        }));

        setSteps(stepItems);

        let customerInfo = null;

        if (customer) {
          customerInfo = {
            title: "",
            cardTitle: "Customer Detatil",
            name: customer[0].name,
            mobile: customer[0].mobile,
            address: customer[0].address,
            system_size: customer[0].system_size,
            system_type: customer[0].system_type,
          };

          chronoItems.unshift(customerInfo);
        }

        // Set the items state
        setItems(chronoItems);
        setCustomerData(customerInfo);
        console.log(customerInfo);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching timelines:", error);
        setLoading(false);
      }
    };

    fetchTimelines();
  }, [mobile]); // Empty dependency array ensures this effect runs only once

  // Log items to check
  console.log(items);

  if (loading) {
    return (
      <Typography variant="body1" sx={{ textAlign: "center" }}>
        Loading...
      </Typography>
    );
  }

  return (
    <Container>
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" sx={{ mb: 5, textAlign: "center" }}>
          Project Timeline
        </Typography>
        {items.length > 0 ? (
          <Chrono
            items={items}
            mode="VERTICAL_ALTERNATING"
            disableToolbar
            theme={{
              primary: "#4D4D4D",
              secondary: "#FFD05B",
              titleColor: "#4D4D4D",
              cardTitleColor: "4D4D4D",
              titleColorActive: "#4D4D4D",
            }}
            contentDetailsHeight="auto"
            cardHeight="auto"
          >
            {customerData && (
              <Box
                sx={{
                  textAlign: "left",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="p">
                  <strong>Name:</strong> {customerData.name}
                </Typography>
                <Typography variant="p">
                  <strong>Mobile:</strong> {customerData.mobile}
                </Typography>
                <Typography variant="p">
                  <strong>Address:</strong> {customerData.address}
                </Typography>
                <Typography variant="p">
                  <strong>System Size:</strong> {customerData.system_size} KW
                </Typography>
                <Typography variant="p">
                  <strong>System Type:</strong> {customerData.system_type}
                </Typography>
              </Box>
            )}

            {steps.map((item, index) => (
              <Box key={index} sx={{ textAlign: "left", width: "100%", pb: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  {" "}
                  <Typography variant="body2" color="blue">
                    {" "}
                    Created at: {new Date(
                      item.created_at
                    ).toLocaleDateString()}{" "}
                    {new Date(item.created_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                  </Typography>{" "}
                  <Typography variant="body2" color="green">
                    {" "}
                    Updated at: {new Date(
                      item.updated_at
                    ).toLocaleDateString()}{" "}
                    {new Date(item.updated_at).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                  </Typography>{" "}
                </Box>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.cardDetailedText }}
                ></div>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {item?.documents &&
                    item.documents.map((file, index) => (
                      <Button
                        href={file} // Link URL
                        target="_blank" // Opens in new tab
                        rel="noopener noreferrer" // Security best practices
                        variant="contained" // Button style
                        sx={{ textTransform: "none" }}
                      >
                        {`document_${index + 1}`}
                      </Button>
                    ))}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  {item.payment_link && (
                    <Button
                      onClick={() => window.open(item?.payment_link, "_blank")}
                      variant="contained"
                      sx={{ ml: 2 }}
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
          </Chrono>
        ) : (
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            No timeline date to render
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default ProjectTimeline;
