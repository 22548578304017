import React from "react";
import { Stack } from "@mui/material";
import SolrufTextField from "../../../components/SolrufTextField/SolrufTextField";

const ProductDimension = ({ dimensions, onInputChange }) => {
  return (
    <Stack
      direction={"row"}
      flexWrap={"wrap"}
      gap={2}
      justifyContent="space-between"
    >
      <SolrufTextField
        size="small"
        type="number"
        label="Height"
        name="height"
        defaultValue={dimensions.height}
        onChange={onInputChange}
        sx={{
          maxWidth: "23%",
        }}
        iconText="cm"
      />

      <SolrufTextField
        size="small"
        type="number"
        label="Weight"
        name="weight"
        defaultValue={dimensions.weight}
        onChange={onInputChange}
        sx={{
          maxWidth: "23%",
        }}
        iconText="kg"
      />
      <SolrufTextField
        size="small"
        type="number"
        label="Length"
        name="length"
        defaultValue={dimensions.length}
        onChange={onInputChange}
        sx={{
          maxWidth: "23%",
        }}
        iconText="cm"
      />
      <SolrufTextField
        size="small"
        type="number"
        label="Breadth"
        name="breadth"
        defaultValue={dimensions.breadth}
        onChange={onInputChange}
        sx={{
          maxWidth: "23%",
        }}
        iconText="cm"
      />
    </Stack>
  );
};

export default ProductDimension;
